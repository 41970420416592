import { computed, defineProps } from 'vue'
import {usePage} from "@inertiajs/inertia-vue3";

export function useColors(props) {

    const page = usePage();

    const textColor = computed(() => page.props.value.textColor);
    const primaryColor = computed(() => page.props.value.primaryColor);

    return {
        textColor: textColor.value,
        primaryColor: primaryColor.value
    }
}